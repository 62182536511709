import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NuncNavigationItem } from '@nunc/components/navigation';
import { TranslocoCoreModule } from '@nunc/lib/config';
import { ProfileService, Role, UserService } from '@nunc/lib/profile';
import { NavigationService } from '@nunc/lib/shared';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { YukawaSplashScreenService } from '@yukawa/chain-base-angular-comp/splash-screen';
import { AuthGuardService, AuthService, Session, SessionChangedEventArgs, SessionService } from '@yukawa/chain-main-angular-session';
import { ISessionChangeOptions } from '@yukawa/chain-main-angular-session/model';
import { defaultNavigation } from 'app/mock-api/common/navigation/data';


const PRE_REG_URL = '/pre-reg';
const SIGN_IN_URL          = '/sign-in';
const DEFAULT_REDIRECT_URL = '/signed-in-redirect';

interface SessionChangeOptions extends ISessionChangeOptions
{
    forceLogin: boolean;
}

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
})
export class AppComponent implements OnInit
{
    /**
     * Constructor
     */
    constructor(
        private readonly _authGuardService: AuthGuardService,
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _configService: ConfigService,
        private readonly _location: Location,
        private readonly _navigationService: NavigationService,
        private readonly _profileService: ProfileService,
        private readonly _router: Router,
        private readonly _sessionService: SessionService,
        private readonly _splashScreenService: YukawaSplashScreenService,
        private readonly _translocoCoreModule: TranslocoCoreModule,
        private readonly _userService: UserService,
    )
    {
        _sessionService.sessionChanged.subscribe(this.sessionChanged, this);
        _sessionService.auth.loginRequest.subscribe(this.loginRequest, this);
    }

    get preRegistration(): boolean
    {
        return this._configService.getValue('preRegistrationDomain') === window.location.hostname;
    }

    get signInUrl(): string
    {
        return this.preRegistration ? PRE_REG_URL : SIGN_IN_URL;
    }

    public async ngOnInit(): Promise<void>
    {
        await this._translocoCoreModule.mergeTranslations((lang: string) => `yukawa/${lang}`);
        await this._translocoCoreModule.mergeTranslations((lang: string) => lang);

        let command: string;
        if (await this._sessionService.restore()) {

            if (this._authGuardService.lastForbiddenRoute) {
                command = this._authGuardService.lastForbiddenRoute;
            }
            else {
                const locationPath = this._location.path(true);
                if (locationPath !== '' && locationPath !== '/') {
                    command = locationPath;
                }
            }

        }

        if (!(await this.initialNavigation(command))) {
            if (this._sessionService.auth.isAuthenticated) {
                this._sessionService.logout({
                    forceLogin: true,
                } as SessionChangeOptions);
            }
            else {
                await this.navigate(this.signInUrl);
            }
        }

        this._splashScreenService.hide();
        this._changeDetector.detectChanges();
    }

    public initialNavigation(command?: string): Promise<boolean>
    {
        const urls               = new Array<string>();
        const initialRedirectUrl = this.getInitialRedirectUrl(urls);

        // Skip non admins without view rights
        if (!this._userService.hasRole(Role.admin) && initialRedirectUrl === DEFAULT_REDIRECT_URL) {
            return Promise.resolve(false);
        }

        let url = command && urls.some(url => command.startsWith(url))
            ? command
            : initialRedirectUrl;
        return this._router.navigateByUrl(url);
    }

    private navigate(url: string): Promise<boolean>
    {
        return this._router.navigateByUrl(window.location.pathname.startsWith(PRE_REG_URL)
            ? window.location.pathname
            : url);
    }

    /**
     * Returns the first navigation item or default redirect url
     *
     * @param  urls
     * @private
     */
    private getInitialRedirectUrl(urls?: Array<string>): string
    {
        if (!urls) {
            urls = new Array<string>();
        }
        const collectUrls = (items: NuncNavigationItem[]): void =>
        {
            for (const _navigationItem of items) {
                if (_navigationItem.hidden && _navigationItem.hidden(_navigationItem)) {
                    continue;
                }
                if (!_navigationItem.children) {
                    urls.push(_navigationItem.link);
                }
                else {
                    collectUrls(_navigationItem.children);
                }
            }
        };
        collectUrls(defaultNavigation);

        return urls?.[0] || DEFAULT_REDIRECT_URL;
    }

    private loginRequest(sender: AuthService): void
    {
        let redirectUrl: string;
        let queryParams = {};
        if (sender.isAuthenticated) {
            redirectUrl = this.getInitialRedirectUrl();
        }
        else {
            if (this._authGuardService.lastForbiddenRoute && !this.preRegistration) {
                queryParams = { redirectURL: this._authGuardService.lastForbiddenRoute };
            }

            redirectUrl = this.signInUrl;
        }

        this._router.navigate([redirectUrl], { queryParams });
    }

    private async sessionChanged(sender: Session, ea: SessionChangedEventArgs): Promise<void>
    {
        if (ea.session instanceof Session.SessionDisconnected) {
            if ((ea.options as SessionChangeOptions)?.forceLogin || this._router.navigated) {
                const redirect = async (): Promise<boolean> => await this.navigate(this.signInUrl);

                if (ea.options?.redirectDelay) {
                    setTimeout(async () =>
                    {
                        await redirect();
                    }, ea.options?.redirectDelay);
                }
                else {
                    await redirect();
                }
            }
        }
    }
}
