import { run } from '@yukawa/chain-base-angular-comp/splash-screen';
import { AppModule } from 'app/app.module';
import { environment } from 'environments';


const gapiLoaded = async (): Promise<void> =>
{
};
Object.defineProperty(window, 'gapiLoaded', {
    get: (): () => Promise<void> => gapiLoaded,
});

run(AppModule, environment.production);
