import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '../service/navigation.service';


@Directive({
    selector: '[backButton]',
})
export class BackButtonDirective
{
    constructor(private _navigation: NavigationService)
    {
    }

    @HostListener('click')
    onClick(): void
    {
        this._navigation.back();
    }
}
