import {AbstractResult, Change, ChangeFilter, Changed, Created, EntityFilter, InstantRange} from '@yukawa/chain-base-angular-domain';
import {GrindingParams, Roast} from './recipe';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.BrewComplete <br/>
*/
export interface BrewComplete {

/**
*	@example 153
*/
brewId?: number;
data?: {[key: string]: Object};
parameters?: BrewParameters;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.BrewCompleteEvent <br/>
*/
export interface BrewCompleteEvent extends  GenericDeviceEvent<BrewComplete> {
recipeId?: number;
shotType?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.BrewCompleteEventFilter <br/>
*/
export interface BrewCompleteEventFilter extends  GenericDeviceEventFilter{
recipeId?: number;
shotTypes?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.BrewParameters <br/>
*/
export interface BrewParameters {

/**
*	@example 1
*/
recipeId?: number;

/**
*	values: SINGLE, DOUBLE<br/>
*	@example SINGLE
*/
shot?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.Device <br/>
*/
export interface Device extends  Changed, Created{
change?: Change;
created?: Change;
deviceId?: string;
fixedFirmware?: string;
testDevice?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.DeviceEvent <br/>
*/
export interface DeviceEvent extends  GenericDeviceEvent<{[key: string]: Object}> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.DeviceFilter <br/>
*/
export interface DeviceFilter extends  EntityFilter{
created?: ChangeFilter;
deviceId?: string;
fixedFirmware?: string;
testDevice?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.DosingIncomplete <br/>
*/
export interface DosingIncomplete extends  GrindBase{}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.DosingIncompleteEvent <br/>
*/
export interface DosingIncompleteEvent extends  GenericGrindEvent<DosingIncomplete> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.DosingIncompleteEventFilter <br/>
*/
export interface DosingIncompleteEventFilter extends  GenericDeviceEventFilter{
hopperId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.ErrorEventEntity <br/>
*/
export interface ErrorEventEntity {
eventData?: EventData;

/**
*	@example afsdfasdg-aegaseg-sadg-ageg
*/
eventId?: string;
payload?: ErrorInfo;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.ErrorEventFilter <br/>
*/
export interface ErrorEventFilter extends  GenericDeviceEventFilter{}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.ErrorInfo <br/>
*/
export interface ErrorInfo {
application?: string;
component?: string;
details?: {[key: string]: Object};
errorClass?: string;

/**
*	values: ERROR, WARN<br/>
*	@example ERROR
*/
logLevel?: string;
message?: string;
stackTrace?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.EventData <br/>
*/
export interface EventData {

/**
*	@example 41245435-2345234
*/
deviceId?: string;

/**
*	@example BREW_COMPLETE
*/
eventType?: string;
timestamp?: string;

/**
*	@example user
*/
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.FluidlineStatus <br/>
*/
export interface FluidlineStatus {
brewerHeadTemperature?: number;
flowPump1?: number;
flowPump2?: number;
fthHeater1Temperature?: number;
fthHeater2Temperature?: number;
pressureCircuit1?: number;
pressureCircuit2?: number;
volumePump1?: number;
volumePump2?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.FluidlineStatusEvent <br/>
*/
export interface FluidlineStatusEvent extends  GenericDeviceEvent<FluidlineStatus> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.FluidlineStatusEventFilter <br/>
*/
export interface FluidlineStatusEventFilter extends  GenericDeviceEventFilter{}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.FullyMapped <br/>
*/
export interface FullyMapped {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GenericDeviceEvent <br/>
*/
export interface GenericDeviceEvent <T> {
eventData?: EventData;

/**
*	@example afsdfasdg-aegaseg-sadg-ageg
*/
eventId?: string;
payload?: T;
setPayload?: undefined | T;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GenericDeviceEventFilter <br/>
*/
export interface GenericDeviceEventFilter extends  EntityFilter{
deviceId?: string;
deviceIds?: string[];
eventId?: string;
eventType?: string;
timestampRange?: InstantRange;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GenericGrindEvent <br/>
*/
export interface GenericGrindEvent <T> extends  GenericDeviceEvent<T> {

/**
*	@example 50
*/
beanWeight?: number;
hopperId?: number;
recipeId?: number;
roastId?: number;
shotType?: string;

/**
*	@example 500
*/
weightAfter?: number;

/**
*	@example 550
*/
weightBefore?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GrindBase <br/>
*/
export interface GrindBase {
grindData?: GrindBase$GrindData;
grindParameter?: GrindingParams;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GrindBase$GrindData <br/>
*/
export interface GrindBase$GrindData {

/**
*	@example 1
*/
consumableId?: number;

/**
*	@example 1
*/
consumableRoastId?: number;

/**
*	@example false
*/
continuation?: boolean;
engineTemperature?: number;
millTemperature?: number;
recipeId?: number;

/**
*	@example SINGLE
*/
shotType?: string;
weightAfter?: number;
weightBefore?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GrindComplete <br/>
*/
export interface GrindComplete extends  GrindBase{}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GrindCompleteEvent <br/>
*/
export interface GrindCompleteEvent extends  GenericGrindEvent<GrindComplete> {
continuation?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GrindCompleteEventFilter <br/>
*/
export interface GrindCompleteEventFilter extends  GenericDeviceEventFilter{
hopperId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.GrindParamFilter <br/>
*/
export interface GrindParamFilter extends  EntityFilter{
deviceId?: string;
deviceIds?: string[];
parameterName?: string;
parameterNames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.Hopper <br/>
*/
export interface Hopper extends  Changed, Created{
change?: Change;
created?: Change;
hopperId?: number;
roast?: Roast;

/**
*	@example 20
*/
shotsLeft?: number;

/**
*	@example 2
*/
shotsPrepared?: number;

/**
*	@example 1700
*/
weightLeft?: number;

/**
*	@example 170
*/
weightUsed?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.HopperFilter <br/>
*/
export interface HopperFilter extends  EntityFilter{
change?: ChangeFilter;
created?: ChangeFilter;
hopperId?: number;
roastId?: number;
username?: string;
withDetachedEmpty?: boolean;
withImageUrls?: boolean;
withRoast?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.IntervalResult <br/>
*/
export interface IntervalResult <T> extends  AbstractResult{

/**
*	@example 10000
*/
interval?: number;
payload?: T;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.LastSystemStatus <br/>
*/
export interface LastSystemStatus {

/**
*	@example 100
*/
attachedHopperId?: number;

/**
*	@example operational
*/
deviceActivity?: string;

/**
*	@example 41245435-2345234
*/
deviceId?: string;
payload?: SystemStatus;

/**
*	@example 100
*/
roastId?: number;
setPayload?: undefined | SystemStatus;
timestamp?: string;

/**
*	@example user
*/
username?: string;

/**
*	@example 60
*/
waterTankLevel?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.LastSystemStatusFilter <br/>
*/
export interface LastSystemStatusFilter extends  GenericDeviceEventFilter{
deviceIds?: string[];
}

export enum ShotType {
SINGLE = "SINGLE",
DOUBLE = "DOUBLE"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.SystemStatus <br/>
*/
export interface SystemStatus extends  FullyMapped{
brewerStatus?: SystemStatus$BrewerStatus;
grinderStatus?: SystemStatus$GrinderStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.SystemStatus$BrewerData <br/>
*/
export interface SystemStatus$BrewerData extends  FullyMapped{

/**
*	@example operational
*/
deviceActivity?: string;

/**
*	@example 60
*/
waterTankLevel?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.SystemStatus$BrewerStatus <br/>
*/
export interface SystemStatus$BrewerStatus extends  FullyMapped{
brewerData?: SystemStatus$BrewerData;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.SystemStatus$GrinderStatus <br/>
*/
export interface SystemStatus$GrinderStatus extends  FullyMapped{
hopperStatus?: SystemStatus$HopperStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.device.SystemStatus$HopperStatus <br/>
*/
export interface SystemStatus$HopperStatus extends  FullyMapped{

/**
*	@example 1
*/
consumableId?: number;

/**
*	@example 1
*/
consumableRoastId?: number;
}

