import { EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort as MaterialSort } from '@angular/material/sort';
import { EntityFilter, QueryResult, TableFilter } from '@yukawa/chain-base-angular-domain';
import { QueryTableSource } from '@yukawa/chain-base-angular-store';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ConstructorFor, Nullable, PlainObject, Required, StringKeys } from 'simplytyped';
import { IQueryTableEntry, IQueryTableEntryDetail } from '../types';


interface IQueryTableEntryDetailGroup extends Required<Partial<IQueryTableEntryDetail>, 'group' | 'key' | 'label'>
{
    details: Array<IQueryTableEntryDetail>;
}

export type EntryGroupDirection = 'vertical' | 'horizontal';

export interface IQueryTableGroupDetail
{
    name: string;
    direction: EntryGroupDirection;
    details: Array<IQueryTableEntryDetail>;
}

export interface Sort<T extends object = PlainObject> extends MaterialSort
{
    active: StringKeys<T>;
}

export abstract class QueryTableStore<E extends IQueryTableEntry, F extends TableFilter | EntityFilter = EntityFilter> extends QueryTableSource<F, E>
{
    public readonly headers          = new Array<IQueryTableEntryDetail | IQueryTableEntryDetailGroup>();
    public readonly displayedColumns = new Array<string>();
    public readonly detailGroups     = new Map<string, Array<IQueryTableGroupDetail>>();
    public readonly detailValues     = new Map<string, Map<string, unknown>>();
    public readonly loaded           = new EventEmitter<Array<E>>();
    public readonly entrySelected    = new EventEmitter<Nullable<E>>(true);
    public readonly actionColumns    = new Array<{ index?: number; name: string }>();

    private _displayedColumns: Array<string>;
    private _selectedEntry: Nullable<E>;

    protected constructor(
        public override readonly paginator: MatPaginator,
        public override readonly sort: MatSort,
        tableFilter: F,
        private readonly tableEntry: ConstructorFor<E>,
        ...displayedColumns: Array<string>)
    {
        super(paginator, sort, tableFilter);

        this.filterSubject = new BehaviorSubject<F>(tableFilter);

        this._displayedColumns = displayedColumns;
        this.showSpinner       = true;
        this.autoLoad = false;
    }

    public abstract get entries(): Array<E>;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public get selectedEntry(): Nullable<E>
    {
        return this._selectedEntry;
    }

    public set selectedEntry(value: Nullable<E>)
    {
        this._selectedEntry = value;
        this.entries.forEach(file => file.selected = file === value);
        this.entrySelected.emit(value);
    }

    public load(): void
    {
        this.autoLoad = true;
        this.reload();
    }

    public override reload(): void
    {
        if (!this.autoLoad) {
            return;
        }
        super.reload();
    }

    /**
     * Retrieves and caches table entry detail values.
     *
     * @param row
     * @param detail
     * @param transform
     * @return
     */
    public getRowDetail<T = unknown>(row: E, detail: IQueryTableEntryDetail, transform?: (value: T) => T): T
    {
        const rowDetail = row.details.get(detail.key);

        let rowMap: Map<string, unknown>;

        if (!this.detailValues.has(row.id)) {
            rowMap = new Map();
            this.detailValues.set(row.id, rowMap);
        }
        else {
            rowMap = this.detailValues.get(row.id) as Map<string, unknown>;
        }

        if (rowMap.has(detail.key)) {
            return rowMap.get(detail.key) as T;
        }

        let value = rowDetail?.value || '';
        value     = transform ? transform(value) : value;

        rowMap.set(detail.key, value);
        return value;
    }

    protected override onLoaded(rows: E[]): void
    {
        super.onLoaded(rows);
        if (this.selectedEntry) {
            const entry = rows.find(row => row.id === this.selectedEntry?.id);
            if (entry) {
                entry.selected     = true;
                this.selectedEntry = entry;
            }
            else {
                this.selectedEntry = null;
            }
        }
        this.init();
    }

    protected override queryTable(): Observable<QueryResult<E>>
    {
        const filter = this.findFilter();

        if (this.sort.direction === '') {
            delete filter['orderBy'];
            delete filter['orderDir'];
            this.sort.active = '';
        }

        this.applyFilter(filter);

        if (filter.keyword && !filter.keyword.startsWith('*')) {
            filter.keyword = `*${filter.keyword}*`;
        }

        return this.queryEntries(filter).pipe(
            tap((response) =>
            {
                this.loaded.emit(response.items);
            }),
        );
    }

    protected override applyFilter(filter: F): void
    {
        super.applyFilter(filter);

        const findOrderByColumn = (): IQueryTableEntryDetailGroup => this.headers
            .find(header => filter.orderBy && (header as IQueryTableEntryDetailGroup).key.startsWith(filter.orderBy)) as IQueryTableEntryDetailGroup;

        let orderByColumn = findOrderByColumn();

        if (!orderByColumn && this.tableEntry) {
            let entries: Array<E>;
            if (this.entries.length > 0) {
                entries = this.entries;
            }
            else {
                const entry = new this.tableEntry();
                entry.init();
                entries = [entry];
            }
            this.init(entries);
            orderByColumn = findOrderByColumn();
        }

        if (orderByColumn) {
            if (orderByColumn.group) {
                const groupByField = this.detailGroups.get(orderByColumn.group)?.reduce<IQueryTableEntryDetail | null>((previous, next) =>
                {
                    if (previous) {
                        return previous;
                    }
                    return next.details
                        .find(_detail => _detail.groupByField === true) as IQueryTableEntryDetail;
                }, null);

                const key = groupByField?.key || filter.orderBy?.split('.').pop();
                if (key?.startsWith(orderByColumn.group)) {
                    filter.orderBy = groupByField && typeof groupByField.groupByField === 'string'
                        ? groupByField.groupByField
                        : key;
                }
                else {
                    filter.orderBy = `${orderByColumn.group}.${groupByField?.key || filter.orderBy?.split('.').pop()}`;
                }
            }
            else if (typeof orderByColumn.groupByField === 'string') {
                filter.orderBy = orderByColumn.groupByField;
            }
        }
    }

    protected entryUpdated(entry: E): void
    {
        this.detailValues.delete(entry.id);
        this.reload();
    }

    protected init(entries: Array<E> = this.entries): void
    {
        entries = [...entries].sort((a: IQueryTableEntry, b: IQueryTableEntry) =>
        {
            const shownDetails = (details: Map<StringKeys<PlainObject>, IQueryTableEntryDetail>): number => Array.from(details.values())
                .reduce<number>((current, next) =>
                {
                    if (next.showInTable) {
                        current++;
                    }
                    return current;
                }, 0);

            if (a.details.size > b.details.size && shownDetails(a.details) > shownDetails(b.details)) {
                return -1;
            }

            if (a.details.size < b.details.size && shownDetails(a.details) < shownDetails(b.details)) {
                return +1;
            }
            return 0;
        });

        const entryDetails = entries.reduce((current, next) =>
        {
            next.details.forEach((detail, key, index) =>
            {
                if (!current.find(_detail => (_detail as IQueryTableEntryDetail).key === detail.key)) {
                    if (!detail.showInTable) {
                        const e = entries.find(entry => entry.details.get(detail.key)?.showInTable);
                        if (e) {
                            detail = e.details.get(detail.key) as IQueryTableEntryDetail;
                        }
                    }
                    current.push(detail);
                }
            });

            const keys = Object.keys(next.viewConfig);
            current.sort((a, b) =>
            {
                const aIndex = keys.indexOf(a.group || a.key);
                const bIndex = keys.indexOf(b.group || b.key);
                if (aIndex < bIndex) {
                    return -1;
                }

                if (aIndex > bIndex) {
                    return +1;
                }
                return 0;
            });

            return current;
        }, new Array<IQueryTableEntryDetail>());

        this.headers.length = 0;
        this.headers.push(...entryDetails.reduce((previous, detail) =>
        {
            if (detail.showInTable) {
                if (!detail.tableGroup) {
                    previous.push(detail as IQueryTableEntryDetail);
                }
                else {
                    const groupByField = entryDetails.find(_detail => _detail.group === detail.group && _detail.groupByField === true);
                    let groupDetail    = previous.find(_detail => _detail.group === detail.group) as IQueryTableEntryDetailGroup;
                    if (!groupDetail) {
                        groupDetail = {
                            group     : detail.group as string,
                            key: groupByField?.key ?? detail.group as string,
                            label     : detail.label,
                            tableGroup: detail.tableGroup,
                            sortable  : detail.sortable,
                            details   : new Array<IQueryTableEntryDetail>(),
                        };
                        previous.push(groupDetail as IQueryTableEntryDetail);
                    }
                    groupDetail.details.push(detail as IQueryTableEntryDetail);

                }
            }
            return previous;
        }, new Array<IQueryTableEntryDetail>()));

        this.displayedColumns.length = 0;
        if (this._displayedColumns && this._displayedColumns.length > 0) {
            this.displayedColumns.push(...this._displayedColumns);
        }
        else {
            this.displayedColumns.push(...this.headers.reduce((previous, next) =>
            {
                if (!previous.includes(next.key)) {
                    previous.push(next.key);
                }
                return previous;
            }, new Array<string>()));
        }

        if (this.displayedColumns.length > 0) {
            this.actionColumns.forEach((column) =>
            {
                if (column.index != null) {
                    this.displayedColumns.splice(column.index, 0, column.name);
                }
                else {
                    this.displayedColumns.push(column.name);
                }
            });
        }

        this.headers.forEach((header) =>
        {
            if ((header as IQueryTableEntryDetailGroup).details) {
                this.detailGroups.set(
                    header.key,
                    this.createDetailGroups((header as IQueryTableEntryDetailGroup).details),
                );
            }
        });

        this.detailValues.clear();
    }

    private createDetailGroups(details: Array<IQueryTableEntryDetail>): Array<IQueryTableGroupDetail>
    {
        return details.sort((detail, other) =>
        {
            if (detail.groupIndex == null || other.groupIndex == null) {
                return 0;
            }

            if (detail.groupIndex > other.groupIndex) {
                return +1;
            }

            if (detail.groupIndex < other.groupIndex) {
                return -1;
            }

            return 0;
        }).reduce((previousValue, currentValue) =>
        {
            let detailGroup = previousValue.find(_detailGroup => _detailGroup.name === currentValue.group
                && _detailGroup.direction === currentValue.groupDirection);

            if (!detailGroup) {
                detailGroup = {
                    name     : currentValue.group as string,
                    direction: currentValue.groupDirection as EntryGroupDirection,
                    details  : new Array<IQueryTableEntryDetail>(),
                };
                previousValue.push(detailGroup);
            }
            detailGroup.details.push(currentValue);

            return previousValue;
        }, new Array<IQueryTableGroupDetail>());
    }

    public abstract dispose?(): void;

    protected abstract queryEntries(filter: F): Observable<QueryResult<E>>;
}
