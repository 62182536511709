import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { EventArgs } from '@awesome-nodes/object';
import { EventDelegate, ObjectModel } from '@yukawa/chain-base-angular-client';


export class NavigateBackEventArgs extends EventArgs
{
    #events = new Array<Promise<void>>();
    #cancel = false;

    public constructor()
    {
        super();
    }

    public get cancel(): boolean
    {
        return this.#cancel;
    }

    public set cancel(value: boolean)
    {
        this.#cancel = value;
    }

    public get events(): Array<Promise<void>>
    {
        return this.#events;
    }
}

export class ToggleFooterEventArgs extends EventArgs
{
    #display: boolean = false;

    constructor(display: boolean)
    {
        super();
        this.#display = display;
    }

    public get display(): boolean
    {
        return this.#display;
    }

    public set display(value: boolean)
    {
        this.#display = value;
    }
}

@Injectable({ providedIn: 'root' })
export class NavigationService extends ObjectModel
{
    public readonly navigateBack: EventDelegate<NavigationService, NavigateBackEventArgs>;
    public readonly toggleFooter: EventDelegate<NavigationService, ToggleFooterEventArgs>;

    #history: string[] = [];

    constructor(
        private readonly _router: Router,
        private readonly _location: Location,
        private readonly _ngZone: NgZone,
    )
    {
        super();
        this._router.events.subscribe((event) =>
        {
            if (event instanceof NavigationEnd) {
                this.#history.push(event.urlAfterRedirects);
            }
        });

        this.navigateBack = new EventDelegate<NavigationService, NavigateBackEventArgs>(this);
        this.toggleFooter = new EventDelegate<NavigationService, ToggleFooterEventArgs>(this);
    }

    async back(extras?: NavigationExtras): Promise<boolean>
    {
        const lastUrl = this.#history.pop();
        const ea      = new NavigateBackEventArgs();
        this.navigateBack.invoke(ea);
        if (ea.events.length > 0) {
            await Promise.all(ea.events);
        }

        if (ea.cancel) {
            if (lastUrl) {
                this.#history.push(lastUrl);
            }
            return false;
        }

        const url = this.#history.pop();

        return this._ngZone.run(async () => url
            ? await this._router.navigateByUrl(url)
            : await this._router.navigate(['..'], extras));
    }
}
