import { Entity } from '@yukawa/chain-base-angular-client';
import { DeepPartial, PlainObject, StringKeys } from 'simplytyped';
import { EntryDetailType, IQueryTableEntry, IQueryTableEntryDetail } from '../types';

// noinspection JSUnusedGlobalSymbols
export abstract class QueryTableEntry<TEntity extends object, TViewConfig extends DeepPartial<TEntity> = TEntity> implements IQueryTableEntry<TEntity>
{
    public readonly details = new Map<StringKeys<TEntity>, IQueryTableEntryDetail>();

    public selected: boolean = false;

    public abstract id: string;
    public abstract name: string;

    protected constructor(
        private readonly _entity: TEntity,
    )
    {
    }

    public get entity(): TEntity
    {
        return this._entity;
    }

    protected get labelTranslationPrefix(): string
    {
        return '';
    }

    public abstract get viewConfig(): TViewConfig;

    public init(): void
    {
        const keys = new Set([
            ...Object.keys(this.viewConfig),
            ...Object.keys(this.entity instanceof Entity ? this.entity.toJson() : this.entity),
        ]);

        for (const _key of keys) {

            const defaultDetail = {
                canEdit        : false,
                required       : false,
                emojiSupport   : false,
                markdownSupport: false,
                showInDetails  : true,
                showInTable: true,
                sortable       : true,
                tableGroup     : false,
                groupDirection : 'vertical',
            } as IQueryTableEntryDetail;

            const details = new Map<string, IQueryTableEntryDetail>();
            this.mapDetails(details, this.entity, _key as never, defaultDetail);

            if (!(this.entity as PlainObject).hasOwnProperty(_key)) {
                continue;
            }

            for (const _detailKey of details.keys()) {
                const _detail = details.get(_detailKey) as IQueryTableEntryDetail;
                if (_detail.value || _detail.required || _detail.canEdit) {
                    this.details.set(_detailKey as StringKeys<TEntity>, _detail);
                }
            }
        }
    }

    protected formatKey(key: string): string
    {
        return this.labelTranslationPrefix
            ? this.labelTranslationPrefix.toUpperCase() + key.toUpperCase()
            : key[0].toUpperCase() + key.substring(1);
    }

    protected mapDetails<TKey = TEntity>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType = 'text';
        let isValue               = false;
        let value: PlainObject[StringKeys<TKey>];

        switch (typeof item) {
            case 'bigint':
            case 'boolean':
            case 'number':
            case 'string':
                isValue = true;
                value   = item;
                break;
            case 'object':
                if ((item instanceof Date)) {
                    isValue = true;
                    value   = item;
                }
                break;
        }
        if (!isValue) {
            value = item?.[key];
        }
        switch (typeof value) {
            case 'bigint':
            case 'number':
                type = 'number';
                break;
            case 'boolean':
                type = 'boolean';
                break;
            case 'string':
                if (value.startsWith('http')) {
                    type = 'url';
                }
                else if (value.length > 0 && detail.type == null && !isNaN(+value)) {
                    type  = 'number';
                    value = +value;
                }
                else {
                    type = 'text';
                }
                break;
            case 'object':
                if (value instanceof Date) {
                    type = 'date';
                }
                else if (Array.isArray(value)) {
                    this.mapDetails(
                        details,
                        value,
                        key,
                        detail,
                    );
                    return;
                }
                else {
                    for (const _key in value) {
                        if (!value.hasOwnProperty(_key)) {
                            continue;
                        }
                        this.mapDetails(
                            details,
                            value[_key],
                            _key as StringKeys<TEntity>,
                            {
                                ...detail,
                                group: key,
                            },
                        );
                    }
                    return;
                }
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, {
            ...detail as Required<IQueryTableEntryDetail>,
            key    : level,
            type,
            label  : this.formatKey(level),
            value,
            options: [],
        });
    }
}
